import { FormControl, Input, Select } from '@atlas-design-system/react'
import {
  FormDatePicker,
  ImageLoader,
  MultiLanguage,
  TextareaField,
} from 'common/components'
import { EMPTY_SELECT_OPTION } from 'common/constants'
import { useAncillaryContext, useCarrierDetailsContext } from 'common/context'
import { ServiceCategory } from 'common/enums'
import {
  alphabeticalSort,
  disabledDateBefore,
  isSubCodeOrServiceTypeCode,
  isTravelportSource,
  matchFromStart,
  MultiLanguageAncillariesSchema,
  noop,
} from 'common/helpers'
import { parseImageDetails } from 'common/helpers/file-upload-helpers'
import { useReferenceData } from 'common/hooks/use-reference-data.hook'
import {
  AncillaryFormValue,
  GalleryItem,
  ImageDetails,
  SelectOption,
} from 'common/types'
import { Field, FormikProps } from 'formik'
import React, { useCallback, useEffect, useMemo } from 'react'
import styled from 'styled-components'

import { AncillaryBaggage } from './components/ancillary-baggage.component'

const StyledCol = styled.div`
  min-width: 205px;
`

type AncillaryBuilderProps = {
  formik: FormikProps<AncillaryFormValue>
  editMode: boolean
  fieldsDisabled: boolean
}

type ImageDetailsList = {
  [ServiceCategory.AGENT]?: ImageDetails
  [ServiceCategory.CONSUMER]?: ImageDetails
}

export const AncillaryBuilder: React.FC<AncillaryBuilderProps> = ({
  formik,
  editMode,
  fieldsDisabled,
}) => {
  const { values, errors, touched, setFieldValue, handleBlur, handleChange } =
    formik

  const {
    groupSelectOptions,
    groupSubGroupMap,
    subGroupSelectOptions,
    groupSubCodeMap,
    subCodeGroupMap,
    ancillaryNameSubcodeMap,
  } = useReferenceData()

  const { selectedAncillary } = useAncillaryContext()
  const userEditPermission = true // TODO: Import User Access
  const { archiveMode } = useCarrierDetailsContext()

  const handleSubgroupChange = useCallback(
    (subgroup: SelectOption<string>) => {
      const { value } = subgroup
      const isBaggage = ['', 'CY'].includes(value)

      setFieldValue('subgroup', subgroup)
      setFieldValue('serviceName', EMPTY_SELECT_OPTION)
      if (isBaggage) {
        setFieldValue('serviceRule', '')
      }
    },
    [setFieldValue],
  )

  const onRemoveImage = (category: ServiceCategory) => {
    const filteredImageDetails = values.imageDetailsList.filter(
      (image) => image.category !== category,
    )
    setFieldValue('imageDetailsList', filteredImageDetails)
  }

  const onServiceNameChange = (value: SelectOption<string>) => {
    setFieldValue('serviceName', value)
    if (!values.group.value) {
      const subCode = ancillaryNameSubcodeMap[value.value]
      const { group, subgroup } = subCodeGroupMap[subCode]
      setFieldValue('group', group)
      setFieldValue('subgroup', subgroup)
    }
  }

  const onServiceNameInputChange = (input: string) => {
    // This allows free form ancillary names in case a selection is not made
    if (input) {
      setFieldValue('serviceName', { value: input, label: input })
    }
  }

  const onSelectImage = (
    category: ServiceCategory,
    selectedGalleryItem: GalleryItem,
  ) => {
    const imageDetailsList = [...values.imageDetailsList]
    const categoryIndex = imageDetailsList.findIndex(
      (image) => image.category === category,
    )
    const selectedImageDetails = parseImageDetails(
      selectedGalleryItem,
      category,
    )

    if (categoryIndex === -1) imageDetailsList.push(selectedImageDetails)
    else imageDetailsList[categoryIndex] = selectedImageDetails

    setFieldValue('imageDetailsList', imageDetailsList)
  }

  const onSelectImageConsumer = (selectedImageDetails: GalleryItem) =>
    onSelectImage(ServiceCategory.CONSUMER, selectedImageDetails)

  const onSelectImageAgent = (selectedImageDetails: GalleryItem) =>
    onSelectImage(ServiceCategory.AGENT, selectedImageDetails)

  const showBaggage = useMemo(
    () =>
      values.group.value === 'BG' && ['', 'CY'].includes(values.subgroup.value),
    [values],
  )

  const imageDetailsList = values.imageDetailsList.reduce<ImageDetailsList>(
    (map, image) => {
      map[image.category] = image
      return map
    },
    {},
  )

  const handleGroupSelect = useCallback(
    (selectedOption: SelectOption<string>) => {
      setFieldValue('group', selectedOption)
      setFieldValue('subgroup', EMPTY_SELECT_OPTION)
      setFieldValue('serviceName', EMPTY_SELECT_OPTION)

      const isBaggage = selectedOption.value === 'BG'

      if (isBaggage) {
        setFieldValue('subgroup', { label: 'Checked Baggage', value: '' })
        setFieldValue('serviceRule', '')
      }
    },
    [setFieldValue],
  )

  const filteredSubGroupSelectOptions = useMemo(() => {
    const { group } = values
    const subGroupNamesList = groupSubGroupMap[group.label] || []

    const filteredItems = !group.value
      ? subGroupSelectOptions
      : subGroupSelectOptions.filter(({ label }) =>
          subGroupNamesList.includes(label),
        )

    if (group.value === 'BG' || !group.value) {
      return [...filteredItems, { value: '', label: 'Checked Baggage' }]
    }

    return filteredItems
  }, [values, groupSubGroupMap, subGroupSelectOptions])

  const serviceNameSelectOptions = useMemo(() => {
    const { group, subgroup } = values

    const asArray = Object.entries(groupSubCodeMap)
    const filteredArray = asArray.filter(
      ([key]) =>
        ![
          'BFFR-Frequent Flyer Accrual',
          'BFSE-Seat',
          'MDWH-Wheelchair',
        ].includes(key),
    )
    const filteredGroupSubCodeMap = Object.fromEntries(filteredArray)
    let nameList

    if (!group.value) {
      nameList = Object.values(filteredGroupSubCodeMap).flat()
    } else if (group.value && !subgroup.value) {
      const keys = Object.keys(filteredGroupSubCodeMap).filter(
        (key) => key.slice(0, 2) === group.value,
      )
      nameList = keys.map((key) => filteredGroupSubCodeMap[key]).flat()
    } else {
      const keyProp = `${group.value}${subgroup.label}`
      nameList = filteredGroupSubCodeMap[keyProp]
    }

    const filteredOptions =
      nameList?.map(({ assoServiceName }) => ({
        label: assoServiceName,
        value: assoServiceName,
      })) || []

    const sortedOptions = alphabeticalSort(filteredOptions, 'label')
    sortedOptions.push(EMPTY_SELECT_OPTION)

    return sortedOptions
  }, [values, groupSubCodeMap])

  const showCodeAndType =
    !isTravelportSource(values) &&
    isSubCodeOrServiceTypeCode(values) &&
    !archiveMode

  const readOnly = useMemo(
    () => fieldsDisabled || !isTravelportSource(values),
    [fieldsDisabled, values],
  )

  const disabledDateBeforeEffectdiveDate = useCallback(
    (value: Date) => disabledDateBefore(value, values.effectiveDate),
    [values.effectiveDate],
  )

  useEffect(() => {
    if (!!values.effectiveDate && !values.discontinueDate) {
      setFieldValue('discontinueDate', values.effectiveDate)
    }
  }, [setFieldValue, values.discontinueDate, values.effectiveDate])

  return (
    <div>
      <MultiLanguage
        disabled={!selectedAncillary}
        label="Create a new ancillary that is not currently listed in ATPCO."
        modalTitle={`${values.group.label} / ${values.serviceName.value}`}
        type="ancillary"
        formik={formik}
        fieldsDisabled={fieldsDisabled}
        validationSchema={MultiLanguageAncillariesSchema}
      />
      <div className="row">
        <div className="col-xs-3">
          <FormControl
            label="Group"
            className="mmp-ui-form-control-required"
            error={formik.touched.group && formik.errors.group?.value}
            disabled={!editMode}
          >
            <Select
              next
              name="group"
              data-testid="ancillary-group-select"
              options={groupSelectOptions}
              onChange={handleGroupSelect}
              onBlur={handleBlur}
              value={values.group}
              filterOption={matchFromStart}
              className="atls-select__menu-400"
              id="group"
              isSearchable
            />
          </FormControl>
        </div>
        <div className="col-xs-3">
          <FormControl label="Sub Group" disabled={!editMode}>
            <Select
              next
              name="subgroup"
              data-testid="ancillary-subgroup-select"
              id="subgroup"
              isSearchable
              options={filteredSubGroupSelectOptions}
              onChange={handleSubgroupChange}
              value={values.subgroup}
              filterOption={matchFromStart}
              className="atls-select__menu-300 atls-select-height-360"
            />
          </FormControl>
        </div>
        {showCodeAndType && (
          <div className="col-xs-4">
            <div className="row">
              <div className="col-xs-2" />
              <div className="col-xs-4">
                <FormControl label="Sub Code" disabled={true}>
                  <Input
                    name="subcode"
                    data-testid="ancillary-subcode-input"
                    value={values.subcode}
                    wrapperProps={{
                      onMouseUp: noop,
                    }}
                  />
                </FormControl>
              </div>
              <div className="col-xs-1" />
              <div className="col-xs-4">
                <FormControl label="Type" disabled={true}>
                  <Input
                    name="serviceTypeCode"
                    data-testid="ancillary-service-type-input"
                    value={values.serviceTypeCode}
                    wrapperProps={{
                      onMouseUp: noop,
                    }}
                  />
                </FormControl>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="row">
        <div className="col-xs-9">
          <FormControl
            label={'Commercial Ancillary Name'}
            className="mmp-ui-form-control-required"
            disabled={!editMode}
            error={
              (formik.touched.serviceName || !!formik.submitCount) &&
              errors.serviceName?.value
            }
          >
            <Select
              next
              name="serviceName"
              id="serviceName"
              data-testid="ancillary-service-name-select"
              isSearchable
              options={serviceNameSelectOptions}
              onChange={onServiceNameChange}
              onInputChange={onServiceNameInputChange}
              onBlur={handleBlur}
              value={values.serviceName}
              className="atls-select-height-360"
            />
          </FormControl>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-9">
          <FormControl
            label="External Commercial Ancillary Name"
            hint="This is the name displayed to the public"
            error={
              touched.titleByType?.EXTERNAL && errors.titleByType?.EXTERNAL
            }
            disabled={!userEditPermission || fieldsDisabled}
            className="mmp-ui-form-control-required"
          >
            <Input
              name="titleByType.EXTERNAL"
              onChange={handleChange}
              onBlur={handleBlur}
              data-testid="ancillary-external-name-input"
              value={values.titleByType.EXTERNAL}
              maxLength="45"
              wrapperProps={{
                onMouseUp: noop,
              }}
            />
          </FormControl>
        </div>
      </div>
      {showBaggage && (
        <AncillaryBaggage formik={formik} disabled={fieldsDisabled} />
      )}
      <div className="row">
        <div className="col-xs-9">
          <FormControl
            label="External Short Ancillary Name"
            hint="This is the name displayed to the public when there is limited space"
            error={touched.titleByType?.SHORT && errors.titleByType?.SHORT}
            disabled={!userEditPermission || fieldsDisabled}
          >
            <Input
              name="titleByType.SHORT"
              data-testid="ancillary-short-name-input"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.titleByType.SHORT}
              maxLength="10"
              wrapperProps={{
                onMouseUp: noop,
              }}
            />
          </FormControl>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-9">
          <FormControl
            label="Strap Line"
            hint="Brief Description"
            error={
              touched.textByCategory?.STRAPLINE &&
              errors.textByCategory?.STRAPLINE
            }
            disabled={!userEditPermission || fieldsDisabled}
          >
            <Input
              name="textByCategory.STRAPLINE"
              data-testid="ancillary-strapline-input"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.textByCategory.STRAPLINE}
              maxLength="100"
              wrapperProps={{
                onMouseUp: noop,
              }}
            />
          </FormControl>
        </div>
      </div>

      <div className="row">
        <div className="col-xs-6">
          <Field
            component={FormDatePicker}
            disabled={fieldsDisabled}
            hint="When the data is active (dd-mmm-yyyy)"
            label="Effective Date"
            name="effectiveDate"
            disabledDate={disabledDateBefore}
            required
          />
        </div>
        <div className="col-xs-6">
          <Field
            component={FormDatePicker}
            disabled={readOnly}
            hint="When the data is no longer active (dd-mmm-yyyy)"
            label="Discontinue Date"
            name="discontinueDate"
            disabledDate={disabledDateBeforeEffectdiveDate}
            required
          />
        </div>
      </div>
      <div className="row">
        <StyledCol className="col-xs-3">
          <ImageLoader
            image={imageDetailsList?.[ServiceCategory.AGENT]?.cdnSourcePath}
            label="Agent Image"
            disabled={!userEditPermission || fieldsDisabled}
            onRemoveImage={() => onRemoveImage(ServiceCategory.AGENT)}
            onSelectImage={onSelectImageAgent}
            restrictions={{
              width: 60,
              height: 60,
              size: 100,
              attribute: 'ancillary',
            }}
          />
        </StyledCol>
        <div className="col-xs">
          <TextareaField
            label="Marketing Text Agent"
            name="textByCategory.MARKETING_AGENT"
            onChange={handleChange}
            value={values.textByCategory.MARKETING_AGENT}
            disabled={!userEditPermission || fieldsDisabled}
          />
        </div>
      </div>
      <div className="row">
        <StyledCol className="col-xs-3">
          <ImageLoader
            image={imageDetailsList?.[ServiceCategory.CONSUMER]?.cdnSourcePath}
            label="Consumer Image"
            disabled={!userEditPermission || fieldsDisabled}
            onRemoveImage={() => onRemoveImage(ServiceCategory.CONSUMER)}
            onSelectImage={onSelectImageConsumer}
            restrictions={{ attribute: 'ancillary' }}
          />
        </StyledCol>
        <div className="col-xs">
          <TextareaField
            label="Marketing Text Consumer"
            name="textByCategory.MARKETING_CONSUMER"
            onChange={handleChange}
            value={values.textByCategory.MARKETING_CONSUMER}
            disabled={!userEditPermission || fieldsDisabled}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs">
          <TextareaField
            label="Ancillary Rules"
            name="serviceRule"
            onChange={handleChange}
            value={values.serviceRule}
            disabled={!userEditPermission || showBaggage || fieldsDisabled}
          />
        </div>
      </div>
    </div>
  )
}
